import {httpDelete, httpGet, httpGetExternal, httpPatch, httpPost, httpPut} from "../libs/http";

export const loadDomainGroups = (page, per_page, filter, onSuccess, onFailure) => dispatch => {
    httpGet('/domains/groups/?page=' + page + '&per_page=' + per_page, (res) => onSuccess(res), (err) => onFailure(err));
};

export const loadAllDomainGroups = (page, per_page, onSuccess, onFailure) => dispatch => {
    httpGet('/domains/groups/?page=' + page + '&per_page=' + per_page, (res) => onSuccess(res), (err) => onFailure(err));
};

export const createDomainGroup = (data, onSuccess, onFailure) => dispatch => {
    httpPost('/domains/groups/', data, (res) => onSuccess(res), (err) => onFailure(err));
};

export const deleteDomainGroup = (guid, onSuccess, onFailure) => dispatch => {
    httpDelete('/domains/groups/' + guid + '/', (res) => onSuccess(res), (err) => onFailure(err));
};

export const getExpiringDomainsInGroup = (guid, onSuccess, onFailure) => dispatch => {
    httpGet('/domains/groups/' + guid + '/expiring/', (res) => onSuccess(res), (err) => onFailure(err));
};

export const loadDomains = (guid, page, per_page, search_query, filters, onSuccess, onFailure) => dispatch => {
    let search = [];

    for(let i = 0; i < search_query.length; i++) {
        search.push('search=' + search_query[i]);
    }

    let search_param = '';

    if(search.length > 0) {
        search_param = '&' + search.join('&')
    }

    let filter_param = '';

    if(filters !== null) {
        let filter_keys = Object.keys(filters);

        for(let i = 0; i < filter_keys.length; i++) {
            if(filters[filter_keys[i]] !== null) {
                for(let j = 0; j < filters[filter_keys[i]].length; j++) {
                    filter_param += '&' + filter_keys[i] + '=' + filters[filter_keys[i]][j];
                }
            }
        }
    }

    httpGet('/domains/?group=' + guid + '&page=' + page + '&per_page=' + per_page + search_param + filter_param, (res) => onSuccess(res), (err) => onFailure(err));
};

export const loadSearchableDomains = (page, per_page, search_query, filters, onSuccess, onFailure) => dispatch => {
    let search = [];

    for(let i = 0; i < search_query.length; i++) {
        search.push('search=' + search_query[i]);
    }

    let search_param = '';

    if(search.length > 0) {
        search_param = '&' + search.join('&')
    }

    let filter_param = '';

    if(filters !== null) {
        let filter_keys = Object.keys(filters);

        for(let i = 0; i < filter_keys.length; i++) {
            if(filters[filter_keys[i]] !== null) {
                for(let j = 0; j < filters[filter_keys[i]].length; j++) {
                    filter_param += '&' + filter_keys[i] + '=' + filters[filter_keys[i]][j];
                }
            }
        }
    }

    httpGet('/domains/?page=' + page + '&per_page=' + per_page + search_param + filter_param, (res) => onSuccess(res), (err) => onFailure(err));
};

export const loadAllDomains = (page, per_page, onSuccess, onFailure) => dispatch => {
    httpGet('/domains/?page=' + page + '&per_page=' + per_page, (res) => onSuccess(res), (err) => onFailure(err));
};

export const loadDomainGroup = (guid, onSuccess, onFailure) => dispatch => {
    httpGet('/domains/groups/' + guid + '/', (res) => onSuccess(res), (err) => onFailure(err));
};

export const updateDomainGroup = (guid, data, onSuccess, onFailure) => dispatch => {
    httpPost('/domains/groups/' + guid + '/', data, (res) => onSuccess(res), (err) => onFailure(err));
};

export const addDomains = (data, onSuccess, onFailure) => dispatch => {
    httpPost('/domains/', data, (res) => onSuccess(res), (err) => onFailure(err));
};

export const loadDomainDetail = (guid, onSuccess, onFailure) => dispatch => {
    httpGet('/domains/' + guid + '/', (res) => onSuccess(res), (err) => onFailure(err));
};

export const loadDomainMeta = (guid, onSuccess, onFailure) => dispatch => {
    httpGet('/domains/' + guid + '/meta/', (res) => onSuccess(res), (err) => onFailure(err));
};

export const updateDomainMeta = (guid, data, onSuccess, onFailure) => dispatch => {
    httpPut('/domains/' + guid + '/meta/', data, (res) => onSuccess(res), (err) => onFailure(err));
};

export const installLetsEncrypt = (guid, data, onSuccess, onFailure) => dispatch => {
    httpPost('/domains/' + guid + '/ssl_certificate/', data, (res) => onSuccess(res), (err) => onFailure(err));
};

export const uninstallSSLCertificate = (guid, onSuccess, onFailure) => dispatch => {
    httpDelete('/domains/' + guid + '/ssl_certificate/', (res) => onSuccess(res), (err) => onFailure(err));
};

export const getSoftwarelist = (guid, onSuccess, onFailure) => dispatch => {
    httpGet('/domains/' + guid + '/software/list/', (res) => onSuccess(res), (err) => onFailure(err));
};

export const getSoftware = (guid, onSuccess, onFailure) => dispatch => {
    httpGet('/domains/' + guid + '/software/', (res) => onSuccess(res), (err) => onFailure(err));
};

export const getSoftwarePassword = (guid, data, onSuccess, onFailure) => dispatch => {
    httpPost('/domains/' + guid + '/software/password/', data, (res) => onSuccess(res), (err) => onFailure(err));
};

export const installSoftware = (guid, data, onSuccess, onFailure) => dispatch => {
    httpPost('/domains/' + guid + '/software/', data, (res) => onSuccess(res), (err) => onFailure(err));
};

export const uninstallSoftware = (guid, onSuccess, onFailure) => dispatch => {
    httpDelete('/domains/' + guid + '/software/', (res) => onSuccess(res), (err) => onFailure(err));
};

export const updateDomainsIPAddress = (guid, data, onSuccess, onFailure) => dispatch => {
    httpPost('/domains/' + guid + '/ip_address/', data, (res) => onSuccess(res), (err) => onFailure(err));
};

export const loadDNSZone = (guid, onSuccess, onFailure) => dispatch => {
    httpGet('/domains/' + guid + '/dns_zone/', (res) => onSuccess(res), (err) => onFailure(err));
};

export const importSoftwareInstallation = (guid, onSuccess, onFailure) => dispatch => {
    httpGet('/domains/' + guid + '/import_software_installation/', (res) => onSuccess(res), (err) => onFailure(err));
};

export const deleteDomain = (guid, onSuccess, onFailure) => dispatch => {
    httpDelete('/domains/' + guid + '/', (res) => onSuccess(res), (err) => onFailure(err));
};

export const deleteDNSRecordDomain = (guid, type, value, onSuccess, onFailure) => dispatch => {
    httpDelete('/domains/' + guid + '/dns_zone/' + type + '/' + value + '/', (res) => onSuccess(res), (err) => onFailure(err));
};

export const createDNSRecord = (guid, data, onSuccess, onFailure) => dispatch => {
    httpPost('/domains/' + guid + '/dns_zone/', data,(res) => onSuccess(res), (err) => onFailure(err));
};

export const editDNSRecord = (guid, data, onSuccess, onFailure) => dispatch => {
    httpPut('/domains/' + guid + '/dns_zone/', data,(res) => onSuccess(res), (err) => onFailure(err));
};

export const resetDNSZone = (guid, onSuccess, onFailure) => dispatch => {
    httpPost('/domains/' + guid + '/dns_zone/reset/', {},(res) => onSuccess(res), (err) => onFailure(err));
};

export const loadSubdomains = (guid, page, per_page, onSuccess, onFailure) => dispatch => {
    httpGet('/domains/' + guid + '/subdomains/?page=' + page + '&per_page=' + per_page, (res) => onSuccess(res), (err) => onFailure(err));
};

export const loadSubdomain = (guid, onSuccess, onFailure) => dispatch => {
    httpGet('/domains/' + guid + '/', (res) => onSuccess(res), (err) => onFailure(err));
};

export const createSubdomain = (guid, data, onSuccess, onFailure) => dispatch => {
    httpPost('/domains/' + guid + '/subdomains/', data,(res) => onSuccess(res), (err) => onFailure(err));
};

export const deleteSubdomain = (guid, subdomainGuid, onSuccess, onFailure) => dispatch => {
    httpDelete('/domains/' + guid + '/', (res) => onSuccess(res), (err) => onFailure(err));
};

export const loadEmailAddresses = (guid, onSuccess, onFailure) => dispatch => {
    httpGet('/domains/' + guid + '/email_addresses/', (res) => onSuccess(res), (err) => onFailure(err));
};

export const createEmailAddress = (guid, data, onSuccess, onFailure) => dispatch => {
    httpPost('/domains/' + guid + '/email_addresses/', data,(res) => onSuccess(res), (err) => onFailure(err));
};

export const deleteEmailAddress = (guid, onSuccess, onFailure) => dispatch => {
    httpDelete('/email_addresses/' + guid + '/', (res) => onSuccess(res), (err) => onFailure(err));
};

export const exportDomainData = (guid, fields, selectedDomains, type, sortField, sortDir, onSuccess, onFailure) => dispatch => {
    let urlFields = [];

    for(let i = 0; i < fields.length; i++) {
        urlFields.push('fields=' + fields[i]);
    }

    let domains = [];

    for(let i = 0; i < selectedDomains.length; i++) {
        domains.push('selected_domains=' + selectedDomains[i]);
    }

    httpGet('/domains/export/?group=' + guid + '&output_type=' + type + '&' + urlFields.join('&') + '&' + domains.join('&') + '&sort_field=' + sortField + '&sort_dir=' + sortDir, (res) => onSuccess(res), (err) => onFailure(err));
};

export const loadUrlRedirects = (guid, page, perPage, searchQuery, onSuccess, onFailure) => dispatch => {
    httpGet('/domains/' + guid + '/redirects/?page=' + page + '&per_page=' + perPage + '&search=' + searchQuery, (res) => onSuccess(res), (err) => onFailure(err));
};

export const createUrlRedirect = (guid, data, onSuccess, onFailure) => dispatch => {
    httpPost('/domains/' + guid + '/redirects/', data, (res) => onSuccess(res), (err) => onFailure(err));
};

export const updateHostingAccount = (guid, data, onSuccess, onFailure) => dispatch => {
    httpPost('/domains/' + guid + '/hosting_account/', data, (res) => onSuccess(res), (err) => onFailure(err));
};

export const updateUrlRedirect = (guid, redirectGuid, data, onSuccess, onFailure) => dispatch => {
    httpPatch('/domains/' + guid + '/redirects/' + redirectGuid + '/', data, (res) => onSuccess(res), (err) => onFailure(err));
};

export const deleteUrlRedirect = (guid, redirect_guid, onSuccess, onFailure) => dispatch => {
    httpDelete('/domains/' + guid + '/redirects/' + redirect_guid + '/', (res) => onSuccess(res), (err) => onFailure(err));
};

export const deleteUrlRedirects = (guid, redirects, onSuccess, onFailure) => dispatch => {
    let redirectsList = [];

    for(let i = 0; i < redirects.length; i++) {
        redirectsList.push('redirect=' + redirects[i]);
    }

    httpDelete('/domains/' + guid + '/redirects/?' + redirectsList.join('&'), (res) => onSuccess(res), (err) => onFailure(err));
};

export const loadGroupTasks = (guid, page, perPage, onSuccess, onFailure) => dispatch => {
    httpGet('/domains/groups/' + guid + '/tasks/?page=' + page + '&per_page=' + perPage, (res) => onSuccess(res), (err) => onFailure(err));
};

export const loginTocPanel = (guid, onSuccess, onFailure) => dispatch => {
    httpGet('/domains/' + guid + '/login/cpanel/', (res) => onSuccess(res), (err) => onFailure(err));
};

export const loginToSoftware = (guid, onSuccess, onFailure) => dispatch => {
    httpGet('/domains/' + guid + '/login/software/', (res) => onSuccess(res), (err) => onFailure(err));
};

export const loadDomainGroupIPAddresses = (guid, onSuccess, onFailure) => dispatch => {
    httpGet('/domains/groups/' + guid + '/ip_addresses/?page=1&per_page=99999', (res) => onSuccess(res), (err) => onFailure(err));
};

export const runBulkDomainActions = (data, onSuccess, onFailure) => dispatch => {
    httpPatch('/domains/', data, (res) => onSuccess(res), (err) => onFailure(err));
};

export const loadSoftwarePresets = (page, perPage, onSuccess, onFailure) => dispatch => {
    httpGet('/domains/software_presets/?page=' + page + '&per_page=' + perPage, (res) => onSuccess(res), (err) => onFailure(err));
};

export const loadSoftwarePresetsMinimal = (onSuccess, onFailure) => dispatch => {
    httpGet('/domains/software_presets/list/', (res) => onSuccess(res), (err) => onFailure(err));
};

export const createSoftwarePreset = (data, onSuccess, onFailure) => dispatch => {
    httpPost('/domains/software_presets/', data, (res) => onSuccess(res), (err) => onFailure(err));
};

export const deleteSoftwarePreset = (guid, onSuccess, onFailure) => dispatch => {
    httpDelete('/domains/software_presets/' + guid + '/', (res) => onSuccess(res), (err) => onFailure(err));
};

export const createSoftwarePresetGroup = (guid, data, onSuccess, onFailure) => dispatch => {
    httpPost('/domains/software_presets/' + guid + '/groups/', data, (res) => onSuccess(res), (err) => onFailure(err));
};

export const addItemsToSoftwarePresetGroup = (preset_guid, group_guid, data, onSuccess, onFailure) => dispatch => {
    httpPost('/domains/software_presets/' + preset_guid + '/groups/' + group_guid + '/items/', data, (res) => onSuccess(res), (err) => onFailure(err));
};

export const deleteSoftwarePresetGroupItem = (preset_guid, group_guid, item_id, onSuccess, onFailure) => dispatch => {
    httpDelete('/domains/software_presets/' + preset_guid + '/groups/' + group_guid + '/items/' + item_id + '/',(res) => onSuccess(res), (err) => onFailure(err));
};

export const deleteSoftwarePresetGroup = (preset_guid, group_guid, onSuccess, onFailure) => dispatch => {
    httpDelete('/domains/software_presets/' + preset_guid + '/groups/' + group_guid + '/',(res) => onSuccess(res), (err) => onFailure(err));
};

export const searchWordpressPlugins = (query, page, perPage, onSuccess, onFailure) => dispatch => {
    httpGetExternal('https://api.wordpress.org/plugins/info/1.1/?action=query_plugins&request[page]=' + page + '&request[per_page]=' + perPage + '&request[search]=' + query, (res) => onSuccess(res), (err) => onFailure(err))
};

export const searchWordpressThemes = (query, page, perPage, onSuccess, onFailure) => dispatch => {
    httpGetExternal('https://api.wordpress.org/themes/info/1.1/?action=query_themes&request[page]=' + page + '&request[per_page]=' + perPage + '&request[search]=' + query, (res) => onSuccess(res), (err) => onFailure(err))
};

export const loadDomainNotes = (guid, onSuccess, onFailure) => dispatch => {
    httpGet('/domains/' + guid + '/notes/', (res) => onSuccess(res), (err) => onFailure(err));
};

export const updateDomainNotes = (guid, data, onSuccess, onFailure) => dispatch => {
    httpPost('/domains/' + guid + '/notes/', data, (res) => onSuccess(res), (err) => onFailure(err));
};

export const getDomainTrafficAnalytics = (guid, startDate, endDate, onSuccess, onFailure) => dispatch => {
    httpGet('/domains/' + guid + '/traffic/?start_date=' + startDate + '&end_date=' + endDate, (res) => onSuccess(res), (err) => onFailure(err));
};

export const getDomainTrafficAnalyticsDetail = (guid, field, page, per_page, startDate, endDate, onSuccess, onFailure) => dispatch => {
    httpGet('/domains/' + guid + '/traffic/detail/?field=' + field + '&start_date=' + startDate + '&end_date=' + endDate + '&page=' + page + '&per_page=' + per_page, (res) => onSuccess(res), (err) => onFailure(err));
};

export const getDomainTrafficAnalyticsDetailGraph = (guid, field, startDate, endDate, onSuccess, onFailure) => dispatch => {
    httpGet('/domains/' + guid + '/traffic/graph/?field=' + field + '&start_date=' + startDate + '&end_date=' + endDate, (res) => onSuccess(res), (err) => onFailure(err));
};